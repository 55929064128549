import styled from 'styled-components'
import { breakpoint } from 'src/styles/mixins'

export const Table = styled.div`
  background: ${props => props.theme.color.white};
  padding: 32px;
`

export const Row = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${props => props.theme.color.lightgrey};

  ${breakpoint.lg`
    display: flex;
    min-height: 72px;
    align-items: center;
  `}

  ${({ last }) =>
    last &&
    `
    border-bottom: none;
  `}
`

export const Heading = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 8px;

  ${breakpoint.lg`
    margin-bottom: 16px;
  `}
`

export const Title = styled.div`
  width: 100%;
  padding-right: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  display: block;

  ${breakpoint.lg`
    width: 35%;
    margin-bottom: 0;
  `}
`
export const Text = styled.div`
  width: 100%;
  display: block;

  ${breakpoint.lg`
    width: 65%;
  `}
`
