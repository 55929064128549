import React from 'react'
import PropTypes from 'prop-types'
import Section from 'src/components/Section'
import Heading2Line from 'src/components/Heading2Line'
import Wysiwyg from 'src/components/Wysiwyg'
import { Grid, Box } from '@material-ui/core/'
import { v4 as uuidv4 } from 'uuid'
import { getThemeVar } from 'src/styles/mixins'

import { Wrapper, Image, Name, Position } from './style'

export const BoardOfDirectors = ({
  preHeading,
  heading,
  boardOfDirectors,
  children,
}) => {
  const boardOfDirectorsMapped = boardOfDirectors?.map(person => (
    <Grid item xs={12} lg={4} key={uuidv4()}>
      <Wrapper mt={{xs: 8, lg: 5}} mb={{xs: 2, lg: -5}}>
        <Image
          fluid={person.portrait.localFile.childImageSharp.fluid}
          alt={person.portrait.altText}
        />
        <Name>{person.name}</Name>
        <Position>{person.position}</Position>
        <Wysiwyg>{person.textWysiwyg}</Wysiwyg>
      </Wrapper>
    </Grid>
  ))

  return (
    boardOfDirectors ? (
      <Section background={getThemeVar('color.lightblue')} hasPaddingLarge>
        <Box mb={8}>
          <Heading2Line preHeading={preHeading} heading={heading} />
          <Box mt={{ xs: 0, lg: 8 }}>
            <Grid container spacing={2}>
              {boardOfDirectorsMapped}
            </Grid>
          </Box>
          {children}
        </Box>
      </Section>
    ) : null
  )
}

BoardOfDirectors.propTypes = {
  preHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  boardOfDirectors: PropTypes.arrayOf(
    PropTypes.shape({
      portrait: PropTypes.object, // eslint-disable-line
      name: PropTypes.string,
      position: PropTypes.string,
      textWysiwyg: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node,
}

BoardOfDirectors.defaultProps = {
  children: undefined,
}

export default BoardOfDirectors
