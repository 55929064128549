import React from 'react'
import PropTypes from 'prop-types'
import Intro from 'src/sections/companyInformation/Intro'
import Chairman from 'src/sections/companyInformation/Chairman'
import BoardOfDirectors from 'src/sections/companyInformation/BoardOfDirectors'
import SectionPattern from 'src/components/SectionPattern'
import LinkButton from 'src/components/LinkButton'
import HeadingWysiwyg from 'src/sections/HeadingWysiwyg'
import { getThemeVar } from 'src/styles/mixins'
import { Box } from '@material-ui/core/'

export const ContactTemplate = ({ acf }) => {
  const companyInformation = acf.companyInformationCompanyInformation
  const chairman = acf.companyInformationChairman
  const boardOfDirectors = acf.companyInformationBoardOfDirectors
  const whatWeDo = acf.companyInformationWhatWeDo
  const investors = acf.companyInformationInvestors
  const career = acf.companyInformationCareer

  return (
    <>
      <Intro
        preHeading={companyInformation.preHeading}
        heading={companyInformation.heading}
        image={companyInformation.image}
        tableHeading={companyInformation.boxHeading}
        table={companyInformation.boxRows}
      >
        <SectionPattern positionX="right" />
      </Intro>

      <Chairman
        preHeading={chairman.preHeading}
        heading={chairman.heading}
        chairman={chairman.chairman}
      >
        <SectionPattern />
      </Chairman>

      <BoardOfDirectors
        preHeading={boardOfDirectors.preHeading}
        heading={boardOfDirectors.heading}
        boardOfDirectors={boardOfDirectors.boardOfDirectors}
      >
        <SectionPattern positionX="right" />
      </BoardOfDirectors>

      <HeadingWysiwyg
        preHeading={whatWeDo.preHeading}
        heading={whatWeDo.heading}
        textWysiwyg={whatWeDo.textWysiwyg}
      >
        <SectionPattern />
      </HeadingWysiwyg>

      <HeadingWysiwyg
        background={getThemeVar('color.lightblue')}
        preHeading={investors.preHeading}
        heading={investors.heading}
        textWysiwyg={investors.textWysiwyg}
      >
        <SectionPattern positionX="right" />
      </HeadingWysiwyg>

      <HeadingWysiwyg
        preHeading={career.preHeading}
        heading={career.heading}
        textWysiwyg={career.textWysiwyg}
        afterText={(
          <Box mt={4}>
            <LinkButton to={career.link.url}>{career.link.title}</LinkButton>
          </Box>
        )}
        beforeFooter
      />
    </>
  )
}

ContactTemplate.propTypes = {
  acf: PropTypes.shape({
    companyInformationCompanyInformation: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      image: PropTypes.object, // eslint-disable-line
      boxHeading: PropTypes.string,
      boxRows: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          title: PropTypes.string,
        })
      ).isRequired,
    }),

    companyInformationChairman: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      chairman: PropTypes.arrayOf(
        PropTypes.shape({
          portrait: PropTypes.object, // eslint-disable-line
          name: PropTypes.string,
          position: PropTypes.string,
          textWysiwyg: PropTypes.string,
        })
      ).isRequired,
    }),

    companyInformationBoardOfDirectors: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      boardOfDirectors: PropTypes.arrayOf(
        PropTypes.shape({
          portrait: PropTypes.object, // eslint-disable-line
          name: PropTypes.string,
          position: PropTypes.string,
          textWysiwyg: PropTypes.string,
        })
      ).isRequired,
    }),

    companyInformationWhatWeDo: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
    }),

    companyInformationInvestors: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
    }),

    companyInformationCareer: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }).isRequired,
    }),
  }).isRequired,
}

export default ContactTemplate
