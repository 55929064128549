import styled from 'styled-components'
import Img from 'gatsby-image'
import { Box } from '@material-ui/core/'
import { breakpoint } from 'src/styles/mixins'

export const Wrapper = styled(Box)`
  background: ${props => props.theme.color.white};
  padding: 32px;

  ${breakpoint.lg`
    height: 100%;
  `}
`

export const Image = styled(Img)`
  max-width: 230px;
  max-height: 230px;
  position: absolute;
  top: -72px;
  margin-bottom: -48px;
`

export const Name = styled.p`
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
`

export const Position = styled.p`
  font-size: 0.8125rem;
  margin: 0;
  color: ${props => props.theme.color.mediumgrey};
`
