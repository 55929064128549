import React from 'react'
import PropTypes from 'prop-types'
import Section from 'src/components/Section'
import Pattern from 'src/components/Pattern'
import Heading2Line from 'src/components/Heading2Line'
import Wysiwyg from 'src/components/Wysiwyg'
import { Grid, Box } from '@material-ui/core/'
import { v4 as uuidv4 } from 'uuid'

import { Wrapper, Image, Name, Position } from './style'

export const Chairman = ({ preHeading, heading, chairman, children }) => {
  const chairmanMapped = chairman.map((person, index) => (
    <Grid item xs={12} lg={6} key={uuidv4()}>
      <Pattern positionY="bottom" positionX={index % 2 ? 'right' : 'left'}>
        <Wrapper mt={{ xs: 8, lg: 5}}>
          {
            person.portrait && 
            person.portrait.localFile && 
            person.portrait.localFile.childImageSharp && (
              <Image
                fluid={person.portrait.localFile.childImageSharp.fluid}
                alt={person.portrait.altText}
              />
            ) 
          }
          <Name>{person.name}</Name>
          <Position>{person.position}</Position>
          <Wysiwyg>{person.textWysiwyg}</Wysiwyg>
        </Wrapper>
      </Pattern>
    </Grid>
  ))

  return (
    <Section hasPaddingLarge>
      <Heading2Line preHeading={preHeading} heading={heading} />
      <Box mt={{ xs: 0, lg: 8 }}>
        <Grid container spacing={8}>
          {chairmanMapped}
        </Grid>
      </Box>
      {children}
    </Section>
  )
}

Chairman.propTypes = {
  preHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  chairman: PropTypes.arrayOf(
    PropTypes.shape({
      portrait: PropTypes.object, // eslint-disable-line
      name: PropTypes.string,
      position: PropTypes.string,
      textWysiwyg: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node,
}

Chairman.defaultProps = {
  children: undefined,
}

export default Chairman
