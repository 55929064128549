import React from 'react'
import PropTypes from 'prop-types'
import Section from 'src/components/Section'
import Heading2Line from 'src/components/Heading2Line'
import ImageWithBackground from 'src/components/ImageWithBackground'
import { getThemeVar } from 'src/styles/mixins'
import { Grid, Box } from '@material-ui/core/'
import { v4 as uuidv4 } from 'uuid'

import { Table, Row, Title, Text, Heading } from './style'

export const Intro = ({
  preHeading,
  heading,
  image,
  tableHeading,
  table,
  children,
}) => {
  const tableMapped = table.map((row, index) => (
    <Row last={index === table.length - 1} key={uuidv4()}>
      <Title>{row.title}</Title>
      <Text>{row.text}</Text>
    </Row>
  ))

  return (
    <Section background={getThemeVar('color.lightblue')} hasPaddingLarge>
      <Grid container spacing={8}>
        <Grid item xs={12} lg={6}>
          <Heading2Line preHeading={preHeading} heading={heading} />
          <Box mt={{ md: 0, lg: 10 }}>
            <Table>
              <Heading>{tableHeading}</Heading>
              {tableMapped}
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ImageWithBackground
            imgFluid={image.localFile.childImageSharp.fluid}
            alt={image.altText}
            position="right"
          />
        </Grid>
      </Grid>
      {children}
    </Section>
  )
}

Intro.propTypes = {
  preHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired, // eslint-disable-line
  tableHeading: PropTypes.string.isRequired,
  table: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node,
}

Intro.defaultProps = {
  children: undefined,
}

export default Intro
